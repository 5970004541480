<template>
  <div class="infobox"
       v-if="showBox"
       v-loading="loading"
       element-loading-text="AI正在思考中...">
    <div class="infobox-iocn-btn" @click="closeItme"><i class="el-icon-circle-close"></i></div>
    <div class="title-list" v-if="titleList.length > 0">
      <div class="title-tips">
        <i class="el-icon-star-off"></i>
        <span>AI智能推荐标题（原创）</span>
      </div>
      <div class="title-item" v-for="item in titleList" @click="transferTitle(item)">{{item}}</div>
      <div class="change-batch" @click="optimize">
        <i class="el-icon-refresh-left"></i>
        <span>换一批</span>
      </div>
    </div>
    <div class="hot-title__detail" v-else>
      <div class="detail-top">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAvBJREFUOE+lk1tIk2EYx//vt+3bwR2cU/GwiMqzxvIEYRZKFkkYUSRUdhldlFAR3ZRgJUk3BVJXQTdBF0UQFEkUtkLBPJSlqXjATXNbO6jbt2+Hb/veL7ZqVEIEvfBcvPA+P54f7/8h+M9D/ux39zRXimBOsvlbdst1pnQa4qKCY/41Q6OPss48f/ahqXQ/IWjb9nLqaKI3BZhtL1Cu2dX35VrVYU1ROaPMzQFhZN/5NI7wpxEqOH0uygk5AJkRY5GWauv8XApgu7r3PjI2tgkrHOJ8BCIfSQ0nYxVQGrXJiruX3IGPy8d9fq6/0WqLJAGunuYWXW3FE3XhJmZ6eAIuuwtbd1hgys0CFSnmPs5gangc+060QBYJ05UX/d057b2XUwqeewfeZuyp2EkYAhAC2/QX+H0cLPXl4LkQxgcmUVZTCL1RA0gSAiM23uCezSanRkNE6oSKsxwKaouMSWGnMwBr72c0H9uBdJM2qTFqnYJj3oGW1ipAFBH1hEV+4ss+k3+wj3hvVZWqNmRParIkgFJMTK+if2AZjQerUFiWC0DC0wdD8C970HqkBCqFhJigAD/rP22cHL5LQjcyzNRcsaQxRAEqgocanxfCKK/dBOurOVAqwWLJTgKKzSwQS7xT06iDb9P7Pz1OKOgD+XWDunxaColC0mgBjQ7vhlxQqhSICSLkjIRMvQlp5s2AyIOzzUF0LZ4rEGy3EwDWZ6g7q92i7FbowECuAOQskMgAkzTAmjMAVdEuqA1yIL6C6IoDi9axWNDN1xBJAsF5qHxFTX2GYrKdURDgx2/8DIIQisHrA/KqzYDAw/l+Mehd8HRaOhZupoLkvtOQQ2TsQ61ZrGd1dF3EPUtB6DdkgAXFWN9MV80Ve8dvUU5cXnc2yEuMyhPKNNou09BKwkhJhXiYsfu8qwt5hcoGeSyOifGvl6qv2a+vA/y6WO7OBq1oUGVKYiSYd8HqHbyYbzaZNG8YSOwaF9tb3WWf+ivgX7f8G71xQ6TEDsDDAAAAAElFTkSuQmCC">
        AI还能进一步优化你的标题哦~
        <div class="detail-top-btn" @click="optimize">立即优化</div>
      </div>
      <div class="detail-main">
        <div class="main-li">
          <div class="main-li-top">输入内容</div>
          <div class="main-li-content content-input">
            电力工业
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAOCAYAAADqtqZhAAAAAXNSR0IArs4c6QAAAB1JREFUGFdjTM0u+88ABYwgGiQwe2oX42DjIDsUAHFmNJk+qqpKAAAAAElFTkSuQmCC">
          </div>
        </div>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAICAYAAACPp21mAAAAAXNSR0IArs4c6QAAAI5JREFUOE9jZBhkYNWq/8wyMsfZrKysviM7jXGQuZPh6LHTMxkY//P9Z/w3y8bCYj/MfYPQoac2IznuFAPDn6lWVlbvGI8dOx0Jk7CyMl0OYw+U+NFjCIeC3PKf4f+n3z+/pQ96hzIw/H/PyPA3fehE/eDMTAx8DP/+zba2Nt83aDPT/v37WdjZ2VnRiycAN5Vlrfp0DB8AAAAASUVORK5CYII=" class="detail-icon">
        <div class="main-li">
          <div class="main-li-top">AI深度分析</div>
          <div class="main-li-content content-ai">
            <div class="content-li">趋势贴合度分析</div>
            <div class="content-li">500亿文献对比</div>
          </div>
        </div>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAICAYAAACPp21mAAAAAXNSR0IArs4c6QAAAI5JREFUOE9jZBhkYNWq/8wyMsfZrKysviM7jXGQuZPh6LHTMxkY//P9Z/w3y8bCYj/MfYPQoac2IznuFAPDn6lWVlbvGI8dOx0Jk7CyMl0OYw+U+NFjCIeC3PKf4f+n3z+/pQ96hzIw/H/PyPA3fehE/eDMTAx8DP/+zba2Nt83aDPT/v37WdjZ2VnRiycAN5Vlrfp0DB8AAAAASUVORK5CYII=" class="detail-icon">
        <div class="main-li" style="width: 160px;">
          <div class="main-li-top">获得标题</div>
          <div class="main-li-content content-ai">
            <div class="content-li">
              <span>●</span>电力系统中基于深度学习学习</div>
            <div class="content-li">
              <span>●</span>智能电网环境下的分布式</div>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="showBox" @click="closeItme"></div>
  </div>
</template>
<script>
import CommonApi from "@/api/common"
export default {
  props: ['title','code'],
  data() {
    return {
      loading: false,
      showBox: false,
      titleList: [],
      titleValue: ''
    }
  },
  created() {
    this.titleValue = this.title
  },
  watch: {
    title(newVal, oldVal) {
      // console.log('newVal=',newVal)
      this.titleValue = newVal
    }
  },
  methods: {
    openItme(){
      this.showBox = true
    },
    closeItme(){
      this.showBox = false
      this.titleList = []
    },
    optimize(){
      if(!this.titleValue){
        return this.$message({
          message: '请先输入标题!',
          type: 'warning',
          duration: 2000,
          customClass: "focus-message",
        });
      }
      if(this.loading){
        return
      }
      this.loading = true
      if(!this.showBox){
        this.openItme()
      }
      // console.log('立即优化')
      this.postOptimize()
    },
    // 标题优化
    postOptimize() {
      let data = {
        creationDocCode: this.code,
        num: 5,
        title: this.titleValue
      }
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      CommonApi.postOptimize(data,key,time,infoMd5).then(res => {
        this.titleList = res.data
        this.loading = false
      }).catch(() => {
      })
    },
    transferTitle(item){
      this.$emit('acceptTitle',item)
      this.closeItme()
    },
  }
}
</script>
<style scoped lang="less">
.infobox{
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 2px;
  background-color: #ffffff;
  box-shadow: 0 6px 20px 1px rgba(0,0,0,.08);
  border-radius: 5px;
  border: 1px solid #ececee;
  .infobox-iocn-btn{
    position: absolute;
    top: 6px;
    right: 16px;
    cursor: pointer;
    i{
      font-size: 24px;
      color: rgba(0,0,0,0.5);
    }
  }
  .title-list{
    padding: 20px;
    .title-tips{
      height: 24px;
      margin-left: 15px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      i{
        font-size: 16px;
        color: #6e83f7;
      }
      span{
        margin-left: 10px;
        color: #6e83f7;
      }
    }
    .title-item{
      height: 34px;
      line-height: 34px;
      margin-left: 20px;
      padding-left: 20px;
      border-radius: 5px;
      cursor: pointer;
    }
    .title-item:hover{
      color: #6e83f7;
      background-color: #eaf5ff;
    }
    .change-batch{
      width: 80px;
      height: 24px;
      margin-left: 15px;
      margin-top: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      i{
        font-size: 16px;
        color: #6e83f7;
      }
      span{
        margin-left: 6px;
        color: #6e83f7;
      }
    }
    .change-batch:hover{
      border-radius: 5px;
      background-color: #eaf5ff;
    }
  }
}

.overlay{
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0,0,0,0.1);
}

.hot-title__detail {
  background: linear-gradient(#f0f8ff,#f2f2ff);
  border-radius: 8px;
  box-sizing: border-box;
  margin: 20px;
  padding: 16px 0
}

.hot-title__detail .detail-top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #2b2f36;
  font-weight: 550
}

.hot-title__detail .detail-top img {
  margin-right: 2px
}

.hot-title__detail .detail-top .detail-top-btn {
  width: 66px;
  height: 22px;
  background: #547dff;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin-left: 6px
}

.hot-title__detail .detail-main {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center
}

.hot-title__detail .detail-main .main-li {
  width: 120px;
  height: 86px;
  background: #fff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #eceef7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.hot-title__detail .detail-main .main-li .main-li-top {
  width: 100%;
  margin: 4px 4px 0 4px;
  height: 28px;
  background: linear-gradient(270deg,rgba(84,125,255,0),#eaefff 34%,#eaefff 68%,rgba(84,125,255,0));
  border-radius: 4px 4px 4px 4px;
  font-weight: 550;
  font-size: 12px;
  color: #3f4654;
  line-height: 28px;
  text-align: center
}

.hot-title__detail .detail-main .main-li .main-li-content {
  flex: 1;
  font-weight: 400;
  font-size: 11px;
  color: #656b76;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px
}

.hot-title__detail .detail-main .main-li .main-li-content img {
  margin-left: 3px
}

.hot-title__detail .detail-main .main-li .content-ai {
  flex-direction: column;
  text-align: center
}

.hot-title__detail .detail-main .main-li .content-li {
  font-weight: 400;
  font-size: 11px;
  color: #656b76;
  width: 136px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.hot-title__detail .detail-main .main-li .content-li span {
  color: #a9beff;
  display: inline-block;
  margin-right: 6px
}

.hot-title__detail .detail-main .detail-icon {
  margin: 0 8px
}
</style>
