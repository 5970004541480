import request from '@/utils/request'

export default {
  // 获取期刊级别接口
  getPeriodicalLevel(){
    return request({
      url: '/periodical/getPeriodicalLevel',
      method: 'get'
    })
  },
  // 获取字数
  getWordsNum(){
    return request({
      url: '/periodical/getWordsNum',
      method: 'get'
    })
  }
}
