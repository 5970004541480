<template>
  <div class="form-box">

    <FormTitle :infoName="infoName" />

    <!-- 标准版 -->
    <div class="form-list" v-show="ruleForm.extParam.modelCode === 1">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入论文标题" class="form-item-pc-show">
          <el-input v-model="ruleForm.title" style="position: relative; z-index: 11;"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    @focus="openTitleInfo"
                    maxlength="50" >
            <div class="btn-i-icon"
                 v-if="ruleForm.title.length > 0"
                 slot="suffix"
                 @click="handleIconClick">
              <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9406"
                   width="20" height="20"><path fill="#2f6bf1" d="M693.952 630.592l193.088 193.152-60.352 60.288L633.6 690.944l60.352-60.352zM614.4 72.512c12.8-4.224 29.888 4.288 29.888 17.088l34.112 226.112c4.288 4.288 4.288 8.576 4.288 12.8L819.2 486.4c8.512 8.512 8.512 25.6-8.512 29.888l-187.776 102.4c-4.224 4.224-8.512 4.224-8.512 8.512L490.688 832c-8.576 12.8-25.6 17.088-34.176 4.288l-153.6-174.976c-4.224-4.224-8.512-8.512-12.8-8.512L89.6 627.2c-12.8-4.288-21.312-17.088-17.088-29.888l89.6-196.224v-8.576l-25.6-226.112c-4.224-17.088 8.576-25.6 21.376-25.6l230.4 8.512H396.8z m-42.688 110.976l-145.024 51.2c-12.8 4.224-29.888 4.224-42.688 4.224L226.112 230.4 243.2 379.712c0 17.088 0 34.176-8.512 51.2l-51.2 123.776 119.424 12.8c25.6 0 51.2 12.8 68.288 34.112l93.888 110.912 81.024-128c8.576-17.024 21.376-29.824 38.4-38.4l119.488-64L618.688 384c-12.8-17.088-21.376-34.112-21.376-55.488l-25.6-145.024z" p-id="9407"></path>
              </svg>
              <span>优化标题</span>
            </div>
          </el-input>
          <TitleInfoBox ref="TitleInfo"
                        :title="ruleForm.title"
                        :code="ruleForm.creationDocCode"
                        @acceptTitle="acceptTitle"/>
        </el-form-item>
        <el-form-item label="请输入论文标题" class="form-item-h5-show">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="选择期刊等级">

          <div class="form-xl radio-pc-show">
            <div class="form-li" v-for="(item,index) in openReportInfo.periodicalLevel" :class="item.active === 1 ? 'active':''"
                 @click="clickNav(index)">
              <div class="form-check" v-if="item.active === 1"><i class="el-icon-check"></i></div>
              <h3>{{item.label}}</h3>
              <p style="line-clamp: 3;-webkit-line-clamp: 3;">{{item.tip}}</p>
            </div>
          </div>

          <el-radio-group v-model="ruleForm.extParam.periodicalLevel" class="radio-h5-show h5-el-radio" @input="clickNavH5">
            <el-radio v-for="item in openReportInfo.periodicalLevel" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item label="请选择报告字数">

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-pc-show">
            <el-radio-button v-for="item in openReportInfo.creationWordsNum" :label="item">{{item + '字左右'}}</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in openReportInfo.creationWordsNum" :label="item">{{item + '字左右'}}</el-radio>
          </el-radio-group>

        </el-form-item>
      </el-form>
    </div>

    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
import PeriodicalApi from '@/api/periodical'
import { orderMixin } from '@/utils/orderMixin'
import { validateTitle } from '@/utils/validate'
import FormTitle from "@/components/FormTitle/index.vue"
import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
import TitleInfoBox from "@/components/titleInfoBox/index.vue"
export default {
  components: {TitleInfoBox, NoticeWrapper, FormTitle},
  mixins: [orderMixin],
  props: ['infoName'],
  data() {
    return {
      ruleForm: {
        title: '',
        creationDocId: 17,
        creationDocCode: 'PERIODICAL',
        channelCode: null,
        extParam: {
          version: 1,
          modelCode: 1,
          periodicalLevel: null,
          creationWordsNum: ''
        }
      },
      // rules: {
      //   title: [
      //     { required: true, message: '请输入开题报告标题', trigger: 'blur' },
      //     { validator: validateTitle, trigger: 'blur' }
      //   ],
      //   extParam: {
      //     periodicalLevel: [
      //       { required: true, message: '请选择期刊等级', trigger: 'change' }
      //     ],
      //     creationWordsNum: [
      //       { required: true, message: '请选择字数', trigger: 'change' }
      //     ]
      //   }
      // },
      btnInfo: {
        title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
        checked: true
      },
      openReportInfo: {
        periodicalLevel: '',
        creationWordsNum: ''
      },
      paramsData: '', // 储存跳页面的传值
    }
  },
  created() {
    this.getPeriodicalLevel()
  },
  methods: {
    // 选择学历
    clickNav(index) {
      this.openReportInfo.periodicalLevel.forEach(item => {
        item.active = 0
      })
      this.openReportInfo.periodicalLevel[index].active = 1
      this.ruleForm.extParam.periodicalLevel = this.openReportInfo.periodicalLevel[index].value
      // let num = this.openReportInfo.periodicalLevel[index].value
      // this.getWordsNum(num)
    },
    // 选择学历 移动端
    clickNavH5(val){
      // console.log(val)
      this.getWordsNum(val)
    },
    // 获取期刊级别接口
    getPeriodicalLevel() {
      PeriodicalApi.getPeriodicalLevel().then(res => {
        // console.log(res.data)
        let dataList = res.data
        dataList.forEach(item=>{
          item.active = 0
        })
        if(dataList.length >= 3){
          // dataList[0].tip = '面向学生及初研者，聚焦基础理论，搜罗国内研究精华，助力学术启航。'
          // dataList[1].tip = '深掘专业议题，理论与实践并重，综合案例与实验数据，适合资深学者交流洞见。'
          // dataList[2].tip = '汇聚领域顶尖成果，展现原创与创新，覆盖国际前沿，资深专家必备研究参考。'
          dataList[0].tip = '专注于基础学术搜索，适合学生和初级研究者，内容涵盖基础理论和国内研究'
          dataList[1].tip = '深入专业话题，深度分析文章，适合有一定经验的学者和专业人士。结合理论与实践，内容包括综合案例分析和实验数据'
          dataList[2].tip = '呈现高水平科研成果，适合领域专家和资深研究者。强调原创性和创新性，内容涵盖国际前沿理论、先进方法论和独到见解'
        }
        this.openReportInfo.periodicalLevel = dataList;
        this.clickNav(0)
        // let num = this.openReportInfo.periodicalLevel[0].value
        this.getWordsNum()
      }).catch(() => {
      })
    },
    // 获取字数
    getWordsNum() {
      PeriodicalApi.getWordsNum().then(res => {
        // console.log(res)
        this.openReportInfo.creationWordsNum = res.data;
        this.ruleForm.extParam.creationWordsNum = this.openReportInfo.creationWordsNum[0]
      }).catch(() => {
      })
    },
    submitForm(formName) {
      let _this = this

      if(_this.ruleForm.title === ''){
        return _this.$message({
          message: '请输入论文标题',
          type: 'warning',
          duration: 2000,
          customClass: "focus-message",
        });
      }else if(_this.ruleForm.title.length < 5){
        return _this.$message({
          message: '标题长度需要至少5个字!',
          type: 'warning',
          duration: 2000,
          customClass: "focus-message",
        });
      }

      _this.paramsData = _this.ruleForm
      let info = JSON.stringify(_this.ruleForm.extParam)
      _this.ruleForm.extParam = info
      //生成订单 mixin
      _this.createOrder(_this.ruleForm,_this.paramsData)

      // if(_this.$refs.outlineRef){
      //   let outlineData = _this.$refs.outlineRef.getOutlineData()
      //   // console.log('outlineData', outlineData)
      //   _this.ruleForm.extParam.outline = JSON.stringify(outlineData)
      // }
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     _this.paramsData = _this.ruleForm
      //     let info = JSON.stringify(_this.ruleForm.extParam)
      //     _this.ruleForm.extParam = info
      //     //生成订单 mixin
      //     _this.createOrder(_this.ruleForm,_this.paramsData)
      //   } else {
      //     return false;
      //   }
      // });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // }
    openTitleInfo(){
      this.$refs.TitleInfo.openItme()
    },
    acceptTitle(item){
      this.ruleForm.title = item
    },
    handleIconClick(ev) {
      // console.log(ev);
      this.$refs.TitleInfo.optimize()
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
.form-item-pc-show{
  display: block;
}
.form-item-h5-show{
  display: none;
}
.btn-i-icon{
  height: 30px;
  padding: 0 8px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #e7f3ff;
  color: #2f6bf1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  span{
    margin-left: 5px;
    font-weight: bold;
  }
}
.btn-i-icon:hover{
  background-color: #d8e3f8;
}

@media screen and (max-width: 900px) {
  .form-item-pc-show{
    display: none;
  }
  .form-item-h5-show{
    display: block;
  }
}
</style>
